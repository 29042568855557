import React from 'react'
import Layout from '../../components/Layout'

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section" style={{ marginBottom: '100px' }}>
          <div className="container contact-page-container">
            <div className="columns">
              <div className="column is-1"></div>
              <div className="column is-10 column-big-responsive">
                <div
                  className="content has-text-centered"
                  style={{ marginTop: '4rem', marginBottom: '4rem' }}
                >
                  <h3
                    className="has-text-weight-semibold is-size-3 has-text-centered"
                    style={{ marginTop: '50px' }}
                  >
                    Polityka prywatności
                  </h3>
                  <div className="content" style={{ textAlign: 'left', marginTop: '30px' }}>
                    <h4>1. Informacje ogólne</h4>
                    <p>
                      Administratorem danych osobowych przetwarzanych w ramach bloga
                      &quot;Coach Krystyna&quot; jest Krystyna Bronowicka, prowadząca działalność
                      nierejestrowaną w Polsce. Kontakt z administratorem możliwy jest za pośrednictwem
                      adresu e-mail: <strong>krystynabronowicka@wp.pl</strong>.
                    </p>

                    <h4>2. Zakres zbieranych danych</h4>
                    <p>
                      Blog zbiera dane osobowe wyłącznie w zakresie:
                      <ul>
                        <li>imienia i adresu e-mail podanych w formularzu kontaktowym.</li>
                      </ul>
                      Dane te służą wyłącznie do odpowiadania na wiadomości przesłane za pośrednictwem
                      formularza kontaktowego.
                    </p>

                    <h4>3. Narzędzia analityczne</h4>
                    <p>
                      Na blogu wykorzystywane jest narzędzie <strong>Google Analytics</strong>, które
                      zbiera anonimowe dane statystyczne na temat ruchu na stronie. Dane te obejmują
                      informacje o używanym urządzeniu, przeglądarce, lokalizacji oraz czasie spędzonym
                      na stronie. Google Analytics może stosować pliki cookies w celu śledzenia
                      aktywności na stronie.
                    </p>

                    <h4>4. Podstawa prawna przetwarzania danych</h4>
                    <p>
                      Dane osobowe są przetwarzane na podstawie zgody użytkownika (art. 6 ust. 1 lit. a
                      RODO), wyrażonej poprzez dobrowolne podanie danych w formularzu kontaktowym.
                    </p>

                    <h4>5. Udostępnianie danych</h4>
                    <p>
                      Dane osobowe nie są udostępniane podmiotom trzecim, za wyjątkiem firmy Google w
                      zakresie korzystania z narzędzia Google Analytics.
                    </p>

                    <h4>6. Prawa użytkowników</h4>
                    <p>
                      Każda osoba, której dane są przetwarzane, ma prawo do:
                      <ul>
                        <li>dostępu do swoich danych,</li>
                        <li>sprostowania danych,</li>
                        <li>usunięcia danych,</li>
                        <li>ograniczenia przetwarzania danych,</li>
                        <li>wniesienia sprzeciwu wobec przetwarzania danych,</li>
                        <li>przenoszenia danych.</li>
                      </ul>
                      Aby skorzystać z powyższych praw, należy skontaktować się z administratorem pod
                      adresem e-mail: <strong>krystynabronowicka@wp.pl</strong>.
                    </p>

                    <h4>7. Okres przechowywania danych</h4>
                    <p>
                      Dane osobowe przesłane za pośrednictwem formularza kontaktowego są przechowywane
                      przez czas potrzebny na udzielenie odpowiedzi na zapytanie, a następnie usuwane.
                    </p>

                    <h4>8. Bezpieczeństwo danych</h4>
                    <p>
                      Administrator stosuje odpowiednie środki techniczne i organizacyjne w celu ochrony
                      danych osobowych, w tym zabezpieczenia przed nieautoryzowanym dostępem.
                    </p>

                    <h4>9. Kontakt</h4>
                    <p>
                      W sprawach związanych z przetwarzaniem danych osobowych prosimy o kontakt na adres
                      e-mail: <strong>krystynabronowicka@wp.pl</strong>.
                    </p>

                    <h4>10. Zmiany w polityce prywatności</h4>
                    <p>
                      Polityka prywatności może być okresowo aktualizowana. Wszelkie zmiany będą
                      publikowane na tej stronie.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
