import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import calendar from '../img/blog-post-calendar.svg'

class MiniBlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.slice(0, 4).map(({ node: post }, index) => (
            <div className="is-parent column is-6" key={post.id}>
              <article className="blog-list-item tile is-child">
                <div className="main-card">
                  <div
                    className={`card-back-frame ${
                      (index === 1 || index === 2) ? 'card-back-frame-blue' : 'card-back-frame-pink'
                    }`}
                  ></div>

                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="blog-list-item-content">
                  <div className="post-meta">
                    <Link
                      className="title is-size-4 text-blue"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <div className="post-meta-details">
                      <img src={calendar} alt="calendar icon" />
                      <p className="subtitle">{post.frontmatter.date}</p>
                    </div>
                  </div>
                  <p>
                    {post.excerpt}
                    <br />
                    <br />
                    <Link to={post.fields.slug} style={{ marginTop: '20px' }}>
                      Czytaj więcej →
                    </Link>
                  </p>
                </div>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

MiniBlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query MiniBlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 4
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                author
                date(formatString: "DD/MM/YYYY", locale: "pl")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <MiniBlogRoll data={data} count={count} />}
  />
)
