import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import MiniBlogRoll from '../components/MiniBlogRoll'
import logo from '../img/logo.svg';
import Testimonials from '../components/Testimonials'
import cert1 from '../img/cert1.png';
import cert2 from '../img/cert2.png';
import left from '../img/arrow-left-white.svg'
import right from '../img/arrow-right-white.svg'


class IndexPageTemplate extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      showGallery: false,
      currentGalleryIndex: 0
    }

    this.certs = new Array(cert1, cert2);

  }
  
  showGallery()
  {
    this.setState({
      showGallery: true,
    });
  }

  hideGallery()
  {
    this.setState({
      showGallery: false,
    });
  }

  rightButtonClicked()
{
  let index = this.state.currentGalleryIndex >= this.certs.length - 1 ? 0 : this.state.currentGalleryIndex + 1;

  this.setState({
    currentGalleryIndex: index
  });
}

leftButtonClicked()
{
  let index = this.state.currentGalleryIndex <= 0 ? this.certs.length - 1 : this.state.currentGalleryIndex - 1;
  
  this.setState({
    currentGalleryIndex: index
  });
}

  render() {

  const image = this.props.image
  const title = this.props.title
  const heading = this.props.heading
  const subheading = this.props.subheading
  const description = this.props.description

return (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image?.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `center center`,
        backgroundAttachment: `fixed`,
        position: `absolute`,
        top: `0`
      }}
    >
      <div className="jumbo-overlay jumbo-overlay-bottom"></div>
      <div className="jumbo-overlay jumbo-overlay-top"></div>

      <div
        style={{
          display: 'flex',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
          position: 'absolute',
          alignItems: 'center'
        }}
      >
        <img className="jumbo-logo" src={logo} alt="Krystyna Bronowicka" />
        <h1 className="jumbo-text"><i>Pomagam kobietom przytłoczonym zmianami życiowymi odkryć swój potencjał i zacząć nową, inspirującą podróż ku samorealizacji.</i></h1>
      </div>
    </div>
    <section className="section section--gradient" style={{marginTop: '330px', marginBottom: '50px'}}>
      <div className="container">
        <div className="section">
                  <MiniBlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                    Więcej wpisów
                    </Link>
          </div>
        </div>
        <Testimonials />
      </div>
    </section>
    { this.state.showGallery ? 
    <div className="cert-gallery">
      <div className="cert-gallery-background" onClick={() => this.hideGallery()}> </div>
     <img src={this.certs[this.state.currentGalleryIndex]} onClick={() => this.hideGallery()}></img>
     <div className="gallery-arrow gallery-arrow-left" onClick={() => this.leftButtonClicked()}><img src={left} /></div>
     <div className="gallery-arrow gallery-arrow-right" onClick={() => this.rightButtonClicked()} ><img src={right} /></div>
    </div>
      : null }
    </div>
)
}

}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout mainPage={true}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        description
      }
    }
  }
`
