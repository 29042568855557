import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import SEO from '../components/seo'
import { Disqus } from 'gatsby-plugin-disqus';
import Img from "gatsby-image"
import BlogSideBar from '../components/BlogSideBar'
import calendarimg from '../img/blog-post-calendar.svg'
import categoryimg from '../img/category.svg'
import defaultImg from '../img/index-me.jpg';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  category,
  tags,
  title,
  date,
  helmet,
  id,
  slug,
  featuredImage,
  previous,
  next
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column" style={{paddingRight: '2rem'}}>
          {featuredImage && (
          <Img
            fluid={
              featuredImage.childImageSharp.fluid
            }
            imgStyle={{ objectFit: 'cover' }}
            style={{ marginBottom: '2rem', marginLeft: '-0.5rem', marginRight: '-1.5rem', maxHeight: '60vh', }}
            alt={title}
          />
        )}
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <div className="blog-post-meta">
              <div className="post-meta-details" >
                <img src={calendarimg} />
                <p className="subtitle">
                  {date}
                </p>

                <img src={categoryimg} style={{marginLeft: "50px"}} />
                <p className="subtitle">
                  {category}
                </p>
              </div>
              
            </div>
            <PostContent content={content} />
            <div className="separator"></div>
            {tags && tags.length ? (
              <div style={{ marginTop: `2rem`, marginBottom: '2rem', display: 'flex', alignItems: 'center'}}>
                <h4 className="text-pink" style={{ marginBottom: '0.4em', marginRight: '1em' }}>Tagi</h4>
                <ul className="blog-sidebar-taglist">
                  {tags.map((tag) => (
                  <li key={tag}>
                      <Link className="btn text-blue" to={`/tag/${kebabCase(tag)}/`}>
                      {tag}
                      </Link>
                  </li>
                  ))}
              </ul>
              </div>
            ) : null}
            <div className="blog-post-end-buttons">
              {previous != null ? 
              <Link className="text-blue" to={previous.fields.slug} style={{fontWeight: "bold"}}>
                ← Poprzedni wpis 
                </Link>
                : null }
                {next != null ? 
                <Link className="text-blue" to={next.fields.slug} style={{fontWeight: "bold", float: "right"}}>
                Następny wpis →
                </Link>
                : null }
              </div>
            <Disqus style={{ marginTop: `4rem` }}
              config={{
                /* Replace PAGE_URL with your post's canonical URL variable */
                url: slug,
                /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
                identifier: id,
                /* Replace PAGE_TITLE with the title of the page */
                title: title,
              }}
            />
          </div>
          <BlogSideBar />
        </div>
        
      </div>
      
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data
  const { next, previous } = pageContext;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        twitterImage={"https://coachkrystyna.pl" + post.frontmatter.featuredimage.childImageSharp.fluid.src}
        ogImage={post.frontmatter.featuredimage ? "https://coachkrystyna.pl" + post.frontmatter.featuredimage.childImageSharp.fixed.src : defaultImg}
      />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        category={post.frontmatter.category}
        id={post.id}
        slug={post.slug}
        featuredImage={post.frontmatter.featuredimage}
        previous={previous}
        next={next}
      />
      
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "DD/MM/YYYY", locale: "pl")
        title
        category
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(maxHeight:1080, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            fixed {
              src
            }
          }
        }
      }
    }
  }
`
